import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import { IframeView } from "@ckeditor/ckeditor5-ui";
import MathIcon from "./images/sigma.svg";
import firebase from "firebase/app";
import "firebase/firestore";
import MathType from "@wiris/mathtype-ckeditor5";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
// import { makeSharedVirtualKeyboard } from "mathlive";

export default class MathPlugin extends Plugin {
  // emitter.on( 'eventName', ( eventInfo, ...args ) => { ... } );

  static get reqires() {
    return [MathType, ImageInsert];
  }

  init() {
    const editor = this.editor;
    let count = 0;
    let currentNode;
    let clicked = false;
    const firebaseConfig = {
      apiKey: "AIzaSyBRslWyLQ6L8Tl9tPqGRGX4NodT10Etsgw",
      authDomain: "math-sigma.firebaseapp.com",
      projectId: "math-sigma",
      storageBucket: "math-sigma.appspot.com",
      messagingSenderId: "608463968058",
      appId: "1:608463968058:web:513491aee7cc68b4e86203",
    };

    // Initialize Firebase
    const app = firebase.initializeApp(firebaseConfig);

    // The button must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add("math", () => {
      // The button will be an instance of ButtonView.
      const button = new ButtonView();
      let iframeRef;

      // Execute a callback function when the button is clicked.
      button.on("execute", async () => {
        if (!clicked) {
          await authenticate();
        }
      });

      document.addEventListener("mathExportEvent", handleEvent, false);
      document.addEventListener("closeEditor", closeEvent, false);
      document.addEventListener("dblclick", checkEdit, false);
      document.addEventListener("submittedCode", authenticate, false);
      document.addEventListener("closeAccessPopup", closeAccessPopup, false);

      function handleEvent(e) {
        console.log(e);
        document
          .getElementById("math-editor " + count)
          .setAttribute("data-src", count);

        editor.execute("insertImage", {
          source: [
            {
              src: e.detail,
              alt: "node-math: " + count,
              id: "node",
            },
          ],
        });

        hideEditor();
      }

      function renderEditor() {
        console.log(window.location);
        const iframe = new IframeView();
        // makeSharedVirtualKeyboard({
        //   virtualKeyboardLayout: "dvorak",
        // });
        iframe.render();
        document.body.appendChild(iframe.element);

        iframe.on("loaded", () => {
          console.log("The iframe has loaded", iframe.element.contentWindow);
        });

        count++;
        iframe.element.id = "math-editor " + count;
        iframe.element.sandbox = "allow-popups allow-scripts allow-same-origin";
        iframe.element.style =
          "width:100vw; height: 100vh;background: #0000004a; position: fixed; top: 0; left: 0; z-index:100; padding: 0; margin: 0;";
        iframe.element.src = "./plugin/math.html";
        iframeRef = iframe;
      }

      function authenticate(e) {
        clicked = true;
        console.log(e);

        const db = firebase.firestore();
        const usersRef = db
          .collection(editor.config._config.mathSerial)
          .doc(window.location.hostname);

        return new Promise((res) => {
          usersRef.get().then((docSnapshot) => {
            if (docSnapshot.exists) {
              usersRef.onSnapshot((doc) => {
                // removeEditor(document.getElementById("access-popup"));
                // localStorage.setItem("EQKEY", e.detail);
                renderEditor();
              });
            } else {
              console.log("not authorized");
              window.alert("Math Equation Plugin Not Authenticated");
              // var event = new CustomEvent("invalidCode");
              // window.parent.document.dispatchEvent(event);
            }
            clicked = false;
          });
        });
      }

      function closeAccessPopup() {
        removeEditor(document.getElementById("access-popup"));
      }

      function closeEvent() {
        var editor = document.getElementById("math-editor " + count);
        var hasSrc = editor.getAttribute("data-src");
        // if new editor cancelled remove else hide
        if (hasSrc) {
          hideEditor();
        } else {
          removeEditor(editor);
        }
      }

      function removeEditor(editor) {
        document.body.removeChild(editor);
      }

      //run when insert image
      function hideEditor() {
        var x = document.getElementById("math-editor " + count);
        let editors = document.querySelectorAll('[id^="math-editor"]');
        let lastEditor = editors[editors.length - 1].id.slice(12);
        count = lastEditor;
        x.style.display = "none";
      }

      function openEditor(formula) {
        console.log(formula);
        count = formula;
        var x = document.querySelector("[data-src='" + formula + "']");
        x.style.display = "block";
      }

      function checkEdit() {
        check();
        console.log(currentNode);
      }

      function check() {
        let selection = window.getSelection();
        if (selection) {
          // get all image nodes
          let mathNodes = document.getElementsByClassName("ck-widget");
          var arr = Array.from(mathNodes);
          arr.map((item) => {
            let formula = item.children[0].alt;
            const isClickInside = item.children[0].contains(event.target);
            // check if math node has been clicked
            if (isClickInside && formula.slice(0, 9) === "node-math") {
              currentNode = formula.slice(11, formula.length);
              openEditor(currentNode);
              return;
            }
          });
        }
      }

      button.set({
        label: "Formula",
        withText: false,
        tooltip: true,
        icon: MathIcon,
        class: "formula-icon",
      });

      return button;
    });
  }
}
